import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';

import Herbal from '../../components/herbal-properties/herbal-properties';
import {
  SpaceBetweenContent,
  SPACE_BETWEEN_CONTENT,
} from '../../Layouts/styled/main';
import EndingContent from '../../Layouts/Footer/ending-content';

interface GraphqlData {
  edges: any;
}

interface HerbalPropertiesPageProps {
  data: {
    allFile: GraphqlData;
  };
  propertiesId: string;
}

const HerbalPropertiesDetail: React.FC<HerbalPropertiesPageProps> = ({
  data,
  propertiesId,
}) => {
  useEffect(() => { }, []);

  return (
    <main className="main">
      <Herbal data={data.allFile.edges} propertiesId={Number(propertiesId)} />

      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
      <EndingContent />

      <SpaceBetweenContent className="d-md-none d-block" />
    </main>
  );
};
export default HerbalPropertiesDetail;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/herbal/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 630, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
